.fund {
  height: 200px;
  width: 350px;
  background-color: grey;
  margin-top: 20px;
  color: white;
  padding: 15px;
  cursor: pointer;
}

.main {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fundBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.test {
  border-radius: 20px;
  color: #fff;
  background: linear-gradient(-45deg, #f0fff0, #f0faff, #f7f0ff, #fff0f1);
  background-size: 400% 400%;
  -webkit-animation: Gradient 10s ease infinite;
  min-height: 100vh;

  -moz-animation: Gradient 10s ease infinite;
  animation: Gradient 10s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
